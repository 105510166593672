a {
    text-decoration: none;
}

.cart {
    padding-right: 3em;

    button {
        color: aliceblue;
        background-color: transparent;
        border: none;
    }
}

/**
* Buttons Section
*/
// define variables for the primary colors
$primary_1: #2ad5eb;
$primary_2: #b2ad7f;
$primary_3: #878f99;

//Network Colors
$facebook_color: #529e91;
$instagram_color: blueviolet;
$whats_app_color: #10b90a;
$tikTok_color: #4f6e58;
$email_color: #2ad5eb;

// Variable declaration and validation
$txtColor: white;
$bgColor: #529e91;
$bgColorSecondary: #A0A0A0;
$borderColor: #acfa74;
$bgHoverColor: gray;
$borderHoverColor: rgb(176, 231, 204);

// Hero Sections
.heroIndexScreen {
    background: linear-gradient(rgba(12, 12, 12, 0.479), rgba(12, 12, 12, 0.479)), url(../static/images/ItemsListContainer/bg_kiro.jpg);
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    color: #ffffff;
    background-attachment: fixed;

    .mpk_button_primary:enabled {
        @extend .mpk_button_primary;
    }

    .mpk_button_primary:disabled {
        @extend .mpk_button_primary;
    }

    .mpk_button_secondary:enabled {
        @extend .mpk_button_secondary;
    }

    .mpk_button_secondary:disabled {
        @extend .mpk_button_secondary;
    }
}

.mainInfo {
   padding-top: 5em;
}

.mainText {
    height: 80vh;
    width: 100%;
    justify-content: start;
    align-items: center;
}

.cardComponent {
    .mpk_button_primary:enabled {
        @extend .mpk_button_primary;
    }

    .mpk_button_primary:disabled {
        @extend .mpk_button_primary;
    }

    a {
        margin: 0 2px 10px 0;
        color: $txtColor;
        background-color: $bgColor;
        border-radius: 12px;
        padding: 10px 15px 10px 15px; // Top | Right | Bottom | Left
        border-style: solid;
        border-color: $borderColor;

        &:hover {
            color: $txtColor;
            background-color: $bgHoverColor;
            border-color: $borderHoverColor;
            border-width: 5px;
        }
    }

    .bodyDescription {
        padding-top: 1em;
    }

    img {
        width: 262px;
        height: 244px;
    }
}

.footerComponent {
    padding-top: 36px;
    padding-bottom: 36px;
}

.fa-facebook-f {
    color: #529e91;
}

.fa-instagram {
    color: blueviolet;
}

.fa-whatsapp {
    color: #10b90a;
}

.fa-tiktok {
    color: #4f6e58;
}

.mpk_link_color {

    & {
        color: #5fd3f3;
    }

    & a {
        color: #5fd3f3;
    }

    & a:hover {
        color: #683e68;
    }
}

.not-available {
    color: red;
    font-weight: bold;
}

.size-elements {
    width: 18rem;
    padding-top: 10px;
}

/* CART Section */
.headerSection {
    height: 15vh;
    padding-top: 10vh;
    width: 100%;
    display: flex;

    .headerTitle {
        width: 100%;
        height: 100%;
        justify-content: right;
        // align-items: right;
        padding-left: 3vh;
        font-weight: bold;
        font-size: 2.5vh;
    }

    .cart {
        justify-content: right;
        padding-right: 3vh;
    }

    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }
}

/*
Buttons
*/

.mpk_button_primary:enabled {
    margin: 0 2px 10px 0;
    color: $txtColor;
    background-color: $bgColor;
    border-radius: 12px;
    padding: 10px 15px 10px 15px; // Top | Right | Bottom | Left
    border-style: solid;
    border-color: $borderColor;

    &:hover {
        color: $txtColor;
        background-color: $bgHoverColor;
        border-color: $borderHoverColor;
        border-width: 5px;
    }
}

.mpk_button_primary:disabled {
    margin: 0 2px 10px 0;
    color: $txtColor;
    background-color: $primary_3;
    border-radius: 12px;
    padding: 10px 15px 10px 15px; // Top | Right | Bottom | Left
    border-style: solid;
    border-color: $borderColor;
    opacity: 0.5;
}

.mpk_button_secondary:enabled {
    margin: 0 2px 10px 0;
    color: $txtColor;
    background-color: $instagram_color;
    border-radius: 12px;
    padding: 10px 15px 10px 15px; // Top | Right | Bottom | Left
    border-style: solid;
    border-color: $borderColor;

    &:hover {
        color: $txtColor;
        background-color: $bgHoverColor;
        border-color: $borderHoverColor;
        border-width: 5px;
    }
}

.mpk_button_secondary:disabled {
    margin: 0 2px 10px 0;
    color: $txtColor;
    background-color: $primary_3;
    border-radius: 12px;
    padding: 10px 15px 10px 15px; // Top | Right | Bottom | Left
    border-style: solid;
    border-color: $borderColor;
    opacity: 0.5;
}

/*
    Animation Section
*/
.hover-underline-animation {
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        transform: scale(0);
        bottom: 0;
        left: 0;
        background-color: #00c3ca;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}