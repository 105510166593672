a {
  text-decoration: none;
}

.cart {
  padding-right: 3em;
}
.cart button {
  color: aliceblue;
  background-color: transparent;
  border: none;
}

/**
* Buttons Section
*/
.heroIndexScreen {
  background: linear-gradient(rgba(12, 12, 12, 0.479), rgba(12, 12, 12, 0.479)), url(../static/images/ItemsListContainer/bg_kiro.jpg);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  background-attachment: fixed;
}

/*  */
.heroProductsScreen {
  background: linear-gradient(rgba(12, 12, 12, 0.479), rgba(12, 12, 12, 0.479)), url(../static/images/ItemsListContainer/bg_servicios.jpg);
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  background-attachment: fixed;
}

.heroNosotrosScreen {
  background: linear-gradient(rgba(12, 12, 12, 0.479), rgba(12, 12, 12, 0.479)), url(../static/images/nosotros/background.jpg);
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  background-attachment: fixed;
}

/*  */
.heroContactoScreen {
  background: linear-gradient(rgba(12, 12, 12, 0.76), rgba(12, 12, 12, 0.76)), url(../static/images/contacto/background.jpg);
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.heroThanksScreen {
  background: linear-gradient(rgba(12, 12, 12, 0.479), rgba(12, 12, 12, 0.479)), url(../static/images/thanks/background.jpg);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  background-attachment: fixed;
}

.heroIndexScreen {
  background: linear-gradient(#6c757d, #529e91);
  width: 100%;
  height: 100%;
  padding-top: 5em;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.mainInfo {
  padding-top: 4em;
}

.mainSubs {
  padding-top: 8em;
}

.mainText {
  height: 80vh;
  width: 100%;
  justify-content: start;
  align-items: center;
}

.cardComponent a {
  margin: 0 2px 10px 0;
  color: white;
  background-color: #529e91;
  border-radius: 12px;
  padding: 10px 15px 10px 15px;
  border-style: solid;
  border-color: #acfa74;
}
.cardComponent a:hover {
  color: white;
  background-color: gray;
  border-color: rgb(176, 231, 204);
  border-width: 5px;
}
.cardComponent .bodyDescription {
  padding-top: 1em;
}
.cardComponent img {
  width: 262px;
  height: 244px;
}

.footerComponent {
  padding-top: 36px;
  padding-bottom: 36px;
}

.fa-facebook-f {
  color: #529e91;
}

.fa-instagram {
  color: blueviolet;
}

.fa-whatsapp {
  color: #10b90a;
}

.fa-facebook-messenger {
  color: #529e91;
}

.fa-tiktok {
  color: #4f6e58;
}

.mpk_link_color {
  color: #5fd3f3;
}
.mpk_link_color a {
  color: #5fd3f3;
}
.mpk_link_color a:hover {
  color: #683e68;
}

.not-available {
  color: red;
  font-weight: bold;
}

.size-elements {
  width: 18rem;
  padding-top: 10px;
}

/* CART Section */
.headerSection {
  height: 15vh;
  padding-top: 10vh;
  width: 100%;
  display: flex;
}
.headerSection .headerTitle {
  width: 100%;
  height: 100%;
  justify-content: right;
  padding-left: 3vh;
  font-weight: bold;
  font-size: 2.5vh;
}
.headerSection .cart {
  justify-content: right;
  padding-right: 3vh;
}
.headerSection button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

/*
Buttons Section
*/

.mpk_button {
  margin: 0 2px 10px 0;
  color: white;
  background-color: #529e91;
  border-radius: 12px;
  padding: 10px 15px 10px 15px;
  border-style: solid;
  border-color: #acc5ea;
}
.mpk_button:hover {
  color: white;
  background-color: gray;
  border-color: rgb(176, 231, 204);
  border-width: 5px;
}

.mpk_button_primary:enabled, .heroIndexScreen .mpk_button_primary:enabled, .cardComponent .mpk_button_primary:enabled {
  margin: 0 2px 10px 0;
  color: white;
  background-color: #529e91;
  border-radius: 12px;
  padding: 10px 15px 10px 15px;
  border-style: solid;
  border-color: #acfa74;
}
.mpk_button_primary:enabled:hover {
  color: white;
  background-color: gray;
  border-color: rgb(176, 231, 204);
  border-width: 5px;
}

.mpk_button_primary:disabled, .heroIndexScreen .mpk_button_primary:disabled, .cardComponent .mpk_button_primary:disabled {
  margin: 0 2px 10px 0;
  color: white;
  background-color: #878f99;
  border-radius: 12px;
  padding: 10px 15px 10px 15px;
  border-style: solid;
  border-color: #acfa74;
  opacity: 0.5;
}

.mpk_button_secondary:enabled, .heroIndexScreen .mpk_button_secondary:enabled {
  margin: 0 2px 10px 0;
  color: white;
  background-color: blueviolet;
  border-radius: 12px;
  padding: 10px 15px 10px 15px;
  border-style: solid;
  border-color: #acfa74;
}
.mpk_button_secondary:enabled:hover {
  color: white;
  background-color: gray;
  border-color: rgb(176, 231, 204);
  border-width: 5px;
}

.mpk_button_secondary:disabled, .heroIndexScreen .mpk_button_secondary:disabled {
  margin: 0 2px 10px 0;
  color: white;
  background-color: #878f99;
  border-radius: 12px;
  padding: 10px 15px 10px 15px;
  border-style: solid;
  border-color: #acfa74;
  opacity: 0.5;
}

/**
* Icons Section
*/
.bg_dark {
  background-color: #212529;
}

.bg_dark div {
  background-color: #212529;
}

.bg_gray {
  background-color: #f0f0f0;
}
.bg_gray div {
  background-color: #f0f0f0;
}

.bg_gray_degree {
  background: linear-gradient(to right, #f0f0f0, #d3d3d3);
}

.three_cards h2 {
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 1em;
  color: #529e91;
}

.three_cards h3 {
  font-weight: bold;
  color: #529e91;
}

.left_card {
  display: flex;
  align-items: center; /* Center vertically */
  text-align: left; /* Align text to the left */
  font-weight: bold;
  font-size: large;
  color: #878f99;
}

.right_Card {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: flex-end; /* Align to the right */
  text-align: left; /* Align text to the left */
  font-weight: bold;
  font-size: large;
  color: #878f99;
}

.image_border {
  border: 1px solid #529e91;
  border-radius: 50px;
}

.iconDog {
  color: dodgerblue;
}

.iconShield {
  color: rgb(15, 105, 75);
}

.iconSecurity {
  color: Mediumslateblue;
}

.noDisplayElement {
  display: none;
}

/*
    Animation Section
*/
.hover-underline-animation {
  display: inline-block;
  position: relative;
}
.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  transform: scale(0);
  bottom: 0;
  left: 0;
  background-color: #00c3ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}



/* Teléfonos móviles (menos de 450px) */
@media (max-width: 450.98px) {
  .mainInfo {
    padding-top: 2em;
  }

  .imagePositionFix {
    padding-top: 40%;
  }

  .carousel-item img {
    padding-top: 1em;
    width: 90vw;
    height: auto; /* Maintain aspect ratio */
    max-width: 100%;
    max-height: 70vh;
    display: block;
    margin: 5px auto; /* Center the image with 5px margin */
  }

  .image_responsive {
    width: 55%;
    height: 55%;
  }
}

/* Teléfonos móviles (menos de 576px) */
@media (min-width: 450.98) and (max-width: 575.98px) {
/* @media (max-width: 575.98px) {*/
  .mainInfo {
    padding-top: 6.7em;
  }

  .carousel-item img {
    padding-top: 6.7em;
    width: 90vw;
    height: auto; /* Maintain aspect ratio */
    max-width: 100%;
    max-height: 70vh;
    display: block;
    margin: 5px auto; /* Center the image with 5px margin */
  }

  .image_responsive {
    width: 55%;
    height: 55%;
  }
}

/* Tabletas (576px a 767px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .mainInfo {
    padding-top: 3em;
  }
  .carousel-item img {
    padding-top: 6.5em;
    width: 80vw;
    height: auto; /* Maintain aspect ratio */
    max-width: 100%;
    max-height: 70vh;
  }

  .image_responsive {
    width: 70%;
    height: 70%;
  }
}

/* Escritorio (768px a 991px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .mainInfo {
    padding-top: 3em;
  }
  .carousel-item img {
    padding-top: 6.5em;
    width: 70vw;
    height: auto; /* Maintain aspect ratio */
    max-width: 100%;
    max-height: 70vh;
  }
}

/* Escritorio grande (992px y más) */
@media (min-width: 992px) {
  .mainInfo {
    padding-top: 4em;
  }
  .carousel-item img {
    padding-top: 3em;
    width: 60vw;
    height: auto; /* Maintain aspect ratio */
    max-width: 100%;
    max-height: 70vh;
  }
}

@media screen and (min-width: 573px) {
  .frame_responsive {
    width: 500px;
  }
  .cotikicha {
    height: 150vh;
  }
}
@media screen and (min-width: 573px) and (max-width: 1024px) {
  .frame_responsive {
    width: 100%;
    height: 100%;
  }
  .cotikicha {
    height: 150vh;
  }
}
@media screen and (min-width: 1024px) {
  .frame_responsive {
    width: 100%;
    height: 100%;
  }
  .cotikicha {
    height: 100vh;
  }
}
